.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.product-images {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.product-images img {
  height: 70px;
  cursor: pointer;
}

.product-images img.active {
  border: solid 2px #007bff;
}

.unit-images {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.unit-images img {
  height: 60px;
  cursor: pointer;
}

.unit-images img.active {
  border: solid 2px #007bff;
}

.units_list__times {
  display: flex;
  margin-top: 20px;
}

.units_list__times .units_list__times_label{
  padding-left: 0px !important;
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  margin-left: 4px;
}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  overflow: hidden;
  width: 200px;
}

.radio.active {
  border: 1px solid #74C92C;
}

.radio-input {
  display: none;
}

.radio-label {
  display: block;
  padding: 0.5rem 1rem;
  background-color: white;
  color: black;
  cursor: pointer;
  width: 200px;
  text-align: center
}

.radio-input:checked + .radio-label {
  background-color: #74c92c;
  color: white;
}

.radio-input:disabled + .radio-label {
  opacity: 0.5;
  cursor: not-allowed;
}

.loader {
  width: 25px;
  height: 24px;
}

.loader__circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.loader__path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 2.5s ease-in-out infinite, 6s ease-in-out infinite;
  animation: dash 2.5s ease-in-out infinite, 6s ease-in-out infinite;
  stroke-linecap: round;
  stroke-width: 5px;
  stroke: white;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.loader-container {
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
}

#address{
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}